//imports ----------------------------------------------------------------------

import ComponentModel from '../../../../lib/utils/ComponentModel.js';


//exports ----------------------------------------------------------------------

export default function PopupModelTemplate(){

  //public api -----------------------------------------------------------------

  return new ComponentModel({
    content: undefined,
  });

}
