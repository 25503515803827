//imports ----------------------------------------------------------------------

import Loader from '../../lib/components/loader/Loader.js';
import '../stylesheets/loader.scss';


//module code block ------------------------------------------------------------

var loader = new Loader();


//exports ----------------------------------------------------------------------

export default loader;
