//imports ----------------------------------------------------------------------

import SelectMenu from '../../lib/components/select_menu/SelectMenu.js';
import '../stylesheets/select_menu.scss';


//module code block ------------------------------------------------------------

var selectMenu = new SelectMenu();


//exports ----------------------------------------------------------------------

export default selectMenu;
