//imports ----------------------------------------------------------------------

import ComponentModel from '../../../utils/ComponentModel.js';


//exports ----------------------------------------------------------------------

export default function SelectMenuOptionModel(){

  //public api -----------------------------------------------------------------

  return new ComponentModel({
    isSelected: false,
  });

}
