//imports ----------------------------------------------------------------------

import '../stylesheets/root.scss';


//module code block ------------------------------------------------------------

var rootNode = document.getElementById('root');


//exports ----------------------------------------------------------------------

export default rootNode;
